<template>
  <v-col cols="12">
    <v-card class="pa-2 main-card" outlined>
      <h2>Terenske akcije</h2>
    </v-card>

    <v-tabs v-model="tab" class="top-tabs">
      <v-tab>
        <v-icon left> mdi-flag-checkered </v-icon>
        Moje akcije
      </v-tab>
      <v-tab>
        <v-icon left> mdi-calendar-month </v-icon>
        Kalendar terenskih akcija
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div v-show="fetchingData" style="text-align: center">
          <v-progress-circular
            indeterminate
            color="#1358a3"
          ></v-progress-circular>
        </div>
        <p v-show="error" class="error-text">{{ error }}</p>

        <h3 class="text-center">Planirano / U tijeku</h3>

        <p v-if="!fieldActivitiesInProgress.length" class="text-center">
          Nema terenskih akcija u ovoj kategoriji.
        </p>
        <v-simple-table v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Datum početka</th>
                <th class="text-left">Datum završetka</th>
                <th class="text-left">Status</th>
                <th class="text-left">Naziv</th>
                <th class="text-left">Mjesto</th>
                <th class="text-left">Lokacija</th>
                <th class="text-left">Tip</th>
                <th class="text-left">Iznos od članarina</th>
                <th class="text-left">Ostali troškovi</th>
                <th class="text-left">Uplaćeno u OZČ</th>
                <th class="text-left">Dugovanje</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in fieldActivitiesInProgress" :key="item.Id">
                <td>{{ formatDate(item.Field_Activity_Start_Date__c) }}</td>
                <td>{{ formatDate(item.Field_Activity_End_Date__c) }}</td>
                <td :class="statusClass(item.Field_activity_status__c)">
                  {{ statusName(item.Field_activity_status__c) }}
                </td>
                <td>{{ item.Name }}</td>
                <td>{{ item.City__c }}</td>
                <td>{{ item.City_location_Address__c }}</td>
                <td>{{ item.Field_activity_type__c }}</td>
                <td>{{ formatCurrency(item.Field_action_income__c) }} €</td>
                <td>{{ formatCurrency(item.Total_cost__c) }} €</td>
                <td>{{ formatCurrency(item.DPH_amount_paid__c) }} €</td>
                <td :class="unpaidAmountClass(item.unpaidAmount)">
                  {{ item.unpaidAmount > 0 ? "+" : ""
                  }}{{ formatCurrency(item.unpaidAmount) }} €
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <h3 class="text-center top-spacing">Završeno / Otkazano</h3>

        <p v-if="!fieldActivitiesCompleted.length" class="text-center">
          Nema terenskih akcija u ovoj kategoriji.
        </p>
        <v-simple-table v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Datum početka</th>
                <th class="text-left">Datum završetka</th>
                <th class="text-left">Status</th>
                <th class="text-left">Naziv</th>
                <th class="text-left">Mjesto</th>
                <th class="text-left">Lokacija</th>
                <th class="text-left">Tip</th>
                <th class="text-left">Iznos od članarina</th>
                <th class="text-left">Ostali troškovi</th>
                <th class="text-left">Uplaćeno u OZČ</th>
                <th class="text-left">Dugovanje</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in fieldActivitiesCompleted" :key="item.Id">
                <td>{{ formatDate(item.Field_Activity_Start_Date__c) }}</td>
                <td>{{ formatDate(item.Field_Activity_End_Date__c) }}</td>
                <td :class="statusClass(item.Field_activity_status__c)">
                  {{ statusName(item.Field_activity_status__c) }}
                </td>
                <td>{{ item.Name }}</td>
                <td>{{ item.City__c }}</td>
                <td>{{ item.City_location_Address__c }}</td>
                <td>{{ item.Field_activity_type__c }}</td>
                <td>{{ formatCurrency(item.Field_action_income__c) }} €</td>
                <td>{{ formatCurrency(item.Total_cost__c) }} €</td>
                <td>{{ formatCurrency(item.DPH_amount_paid__c) }} €</td>
                <td :class="unpaidAmountClass(item.unpaidAmount)">
                  {{ item.unpaidAmount > 0 ? "+" : ""
                  }}{{ formatCurrency(item.unpaidAmount) }} €
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-tab-item>

      <v-tab-item>
        <FieldActivityCalendar />
      </v-tab-item>
    </v-tabs-items>
  </v-col>
</template>

<script>
import apiRequest from "../helpers/apiRequest";
import FieldActivityCalendar from "../components/field-activities/FieldActivityCalendar.vue";
import mixin from "../mixins/mixin";

export default {
  name: "FieldActivities",

  components: {
    FieldActivityCalendar,
  },

  mixins: [mixin],

  data() {
    return {
      myFieldActivities: [],
      tab: 0,
      fetchingData: false,
      error: false,
    };
  },

  async created() {
    await this.fetchFieldActivities();
  },

  methods: {
    async fetchFieldActivities() {
      this.error = false;
      this.fetchingData = true;

      try {
        this.myFieldActivities = await apiRequest("get", "field-activities/my");
      } catch (error) {
        this.error = error.message;
      } finally {
        this.fetchingData = false;
      }
    },

    statusClass(status) {
      switch (status) {
        case "Completed":
          return "text-blue";
        case "In progress":
          return "text-green";
        case "Cancelled":
          return "text-red";
      }
    },

    statusName(status) {
      switch (status) {
        case "Completed":
          return "Završeno";
        case "In progress":
          return "U tijeku";
        case "Cancelled":
          return "Otkazano";
        case "Planned":
          return "Planirano";
        default:
          return status;
      }
    },

    unpaidAmountClass(amount) {
      if (amount > 0) {
        return "text-green";
      } else if (amount < 0) {
        return "text-red";
      } else {
        return "";
      }
    },
  },

  computed: {
    fieldActivitiesInProgress() {
      return this.myFieldActivities.filter(
        (item) =>
          item.Field_activity_status__c != "Completed" &&
          item.Field_activity_status__c != "Cancelled"
      );
    },

    fieldActivitiesCompleted() {
      return this.myFieldActivities.filter(
        (item) =>
          item.Field_activity_status__c == "Completed" ||
          item.Field_activity_status__c == "Cancelled"
      );
    },
  },
};
</script>

<style scoped>
.text-green {
  font-weight: 600;
}

.top-tabs {
  margin-bottom: 20px;
}

.top-spacing {
  margin-top: 20px;
}
</style>
