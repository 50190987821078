<template>
  <div>
    <div v-show="fetchingData" style="text-align: center">
      <v-progress-circular indeterminate color="#1358a3"></v-progress-circular>
    </div>
    <p v-show="error" class="error-text">{{ error }}</p>

    <v-sheet tile height="54" class="d-flex">
      <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <p class="calendar-month-name">{{ currentCalendarMonth }}</p>
      <v-spacer> </v-spacer>

      <v-btn icon class="ma-2" @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>

    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        locale="HR"
        type="month"
        v-model="calendarValue"
        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        :events="allFieldActivities"
        @change="calendarChange"
        @click:event="showEvent"
        @click:more="showEventsOnDay"
      ></v-calendar>

      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card color="grey lighten-4" min-width="350px" flat>
          <v-toolbar :color="selectedEvent.color" dark>
            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="selectedOpen = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <p>Naziv akcije: {{ selectedEvent.title }}</p>
            <p>Organizator: {{ selectedEvent.organizer }}</p>
            <p>Mjesto: {{ selectedEvent.city }}</p>
            <p>Lokacija: {{ selectedEvent.location }}</p>
            <p>Datum: {{ formatDate(selectedEvent.start) }}</p>
            <p>Tip akcije: {{ selectedEvent.type }}</p>
            <p>Status: {{ selectedEvent.status }}</p>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-sheet>

    <v-dialog v-model="dayDialog.isShown" width="800px">
      <v-card>
        <v-card-title class="text-h5 gray lighten-2">
          Sve terenske akcije na dan {{ formatDate(dayDialog.date) }}
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Organizator</th>
                  <th class="text-left">Naziv akcije</th>
                  <th class="text-left">Mjesto</th>
                  <th class="text-left">Datum početka</th>
                  <th class="text-left">Datum završetka</th>
                  <th class="text-left">Tip akcije</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="event in dayDialog.events" :key="event.title">
                  <td>{{ event.organizer }}</td>
                  <td>{{ event.title }}</td>
                  <td>{{ event.city }}</td>
                  <td>{{ formatDate(event.start) }}</td>
                  <td>{{ formatDate(event.end) }}</td>
                  <td>{{ event.type }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <table class="legend">
      <tr>
        <td>
          <div class="pa-4 secondary text-no-wrap rounded-pill activity-1">
            Terenska akcija
          </div>
        </td>
        <td>
          <div class="pa-4 secondary text-no-wrap rounded-pill activity-2">
            Samostalna akcija
          </div>
        </td>
        <td>
          <div class="pa-4 secondary text-no-wrap rounded-pill activity-3">
            TeamViewer akcija
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import apiRequest from "../../helpers/apiRequest";
import mixin from "../../mixins/mixin";

export default {
  name: "FieldActivityCalendar",

  mixins: [mixin],

  data() {
    return {
      fetchingData: false,
      error: false,
      allFieldActivities: [],
      currentCalendarMonth: "",
      calendarValue: "",
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      dayDialog: {
        isShown: false,
        date: null,
        events: [],
      },
    };
  },

  async created() {
    const events = await this.fetchAllFieldActivities();
    this.assignColorToEvents(events);
    this.allFieldActivities = events;
  },

  methods: {
    async fetchAllFieldActivities() {
      this.error = false;
      this.fetchingData = true;

      try {
        return await apiRequest("get", "field-activities/all");
      } catch (error) {
        this.error = error.message;
      } finally {
        this.fetchingData = false;
      }
    },

    calendarChange(e) {
      const month = new Date(e.start.date).toLocaleDateString("HR", {
        year: "numeric",
        month: "long",
      });

      this.currentCalendarMonth =
        month.charAt(0).toUpperCase() + month.slice(1);
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },

    /**
     * Ako ima više eventova u istom danu, moguće je da se neće
     * moći prikazati na kalendaru. Ova funkcija prikazuje sve eventove u danu
     * koristeći modal dialog
     */
    showEventsOnDay(e) {
      this.dayDialog.date = e.date;
      this.dayDialog.events = this.allFieldActivities.filter((item) => {
        return item.start == e.date;
      });
      this.dayDialog.isShown = true;
    },

    assignColorToEvents(events) {
      events.forEach((event) => {
        switch (event.type) {
          case "Samostalna akcija":
            event.color = "#f39c12";
            break;
          case "SF uz Team Viewer":
            event.color = "#e74c3c";
            break;
          default:
            event.color = "#1976d2";
            break;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-month-name {
  text-align: center;
  width: 100%;
  margin: 16px;
  font-weight: 600;
}

.legend {
  margin-top: 10px;

  .rounded-pill {
    font-size: 0.8em;
    padding: 2px 16px !important;
    margin-right: 6px;
    color: #fff;
    border-color: #fff !important;

    &.activity-1 {
      background-color: #1976d2 !important;
    }
    &.activity-2 {
      background-color: #f39c12 !important;
    }
    &.activity-3 {
      background-color: #e74c3c !important;
    }
  }
}
</style>
